/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react'
import { useGlobalContext } from '../../contexts/global'
import { toastError } from '../../utils/toast'
import CameraCard from '../common/Camera/CameraCard'
import { ListNote, NoteError } from '../common/Note'
import Title from '../common/Title'

export default function CheckCardFront({ onSuccess }) {
  const [err, setErr] = useState('')
  const { setSessionIdFront, setImgCardFront, setLoading, checkDataValid } = useGlobalContext()

  const handleSubmit = useCallback(async (imgSrc) => {
    const blobImage = await fetch(imgSrc).then(res => res.blob());
    if (!window.EKYC) return;
    try {
      setLoading(true)
      const { data } = await window.EKYC.uploadPhoto(blobImage, 'front')
      if (data.session_id) {
        setImgCardFront(imgSrc)
        setSessionIdFront(data.session_id)
        onSuccess && onSuccess()
      }
    } catch (error) {
      checkDataValid(error)
      const message = error.msg
      setErr(message)

    }
    setLoading(false)

  }, [])

  return (
    <div className='ekyc-front-wrap w-full flex flex-col items-center'>
      <Title text='Chụp mặt trước giấy tờ' />
      <span className='text-xl mb-6'>Vui lòng</span>
      {!err ?
        <ListNote warnings={[
          'Đặt giấy tờ nằm vừa khung hình chữ nhật, chụp đủ sáng và rõ nét',
        ]} />
        :
        <NoteError text={err} />
      }

      <CameraCard onSuccess={handleSubmit} allowNextStep={!err} onPlay={() => { setErr('') }} />
    </div>
  )
}
