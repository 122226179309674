import React from 'react'
import Popup from '.'

export default function Loading({ isShow }) {
  return (
    <Popup
      isShow={isShow}
      classWrap="ekyc-popup-loading"
    />
  )
}
