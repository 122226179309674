import './App.css';
import {
  Route,
  Routes,
} from 'react-router-dom'
import Home from './screens/Home';
import { GlobalProvider } from './contexts/global';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CameraProvider } from './contexts/camera';
function AppImpl() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );
}

const App = () => (
  <GlobalProvider >
    <CameraProvider>
      {/* <RouterProvider router={router} /> */}
      <AppImpl />
      <ToastContainer />
    </CameraProvider>
  </GlobalProvider>
)

export default App

