import React, { useCallback } from 'react'
import { useCameraContext } from '../../contexts/camera'
import { useGlobalContext } from '../../contexts/global'
import { toastError } from '../../utils/toast'
import Button from '../common/Button'
import { ListNote } from '../common/Note'
import Title from '../common/Title'

const LIST_CARD = [
  {
    image: 'images/intro1.png',
    text: 'Môi trường đủ sáng'
  },
  {
    image: 'images/intro2.png',
    text: 'Không đeo kính râm'
  },
  {
    image: 'images/intro3.png',
    text: 'Không đeo khẩu trang'
  },

]


export default function Intro({ onSuccess }) {
  const { isValidCam, err, requireAccess } = useCameraContext()
  const { setTransactionId } = useGlobalContext()

  const handleNextStep = useCallback(async () => {
    if (!window.EKYC) return toastError('Không tìm thấy SDK EKYC!');
    try {
      const { code, data, } = await window.EKYC.createTransaction()
      setTransactionId(data.transaction_id)
      onSuccess && onSuccess()
    } catch (err) {
      toastError(err.msg)
    }

  }, [])

  return (
    <div className='ekyc-intro-wrap flex flex-col items-center'>
      <Title text='Hướng dẫn xác thực' />
      <span className='text-xl mb-6'>Trước khi bắt đầu hãy đảm bảo rằng</span>
      <ListNote warnings={[
        'Giấy tờ còn hạn sử dụng, là giấy tờ gốc, không phải scan hoặc photocopy',
        'Không để ánh sáng lóa giấy tờ khi chụp ảnh',
        'Không chụp giấy tờ mất góc, bị nhòe',
        'Không chụp ảnh từ màn hình thiết bị điện thoại, máy tính...',
      ]} />
      <div className='ekyc-intro__list-card'>
        {LIST_CARD.map(({ image, text }) => (
          <div className='ekyc-intro__card' key={text}>
            <img src={image} alt="intro ekyc" className='ekyc-intro__card__img' />
            <span className='ekyc-intro__card__text'>{text}</span>
          </div>
        ))}
      </div>

      <Button text='Tiếp tục' mt={70} disable={!isValidCam} onClick={handleNextStep} />
      {err && <div className='ekyc-try-cam'>
        <span className='text-danger ekyc-try-cam__message'>{err}</span>
        <span className='ekyc-try-cam__button' onClick={requireAccess}>Thử lại</span>
      </div>}
    </div>
  )
}
