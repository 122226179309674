import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import Button from '../components/common/Button'
import Popup from '../components/common/Popup'
import Loading from '../components/common/Popup/Loading'
import useTogglePopup from '../hooks/useTogglePopup'
import { TAB_INTRO, TAB_PREVIEW, TAB_SUBMIT } from '../contants/tab'

export const defaultValueGlobalCtx = {

}

export const GlobalCtx = createContext(defaultValueGlobalCtx)
export const useGlobalContext = () => useContext(GlobalCtx)

export const GlobalProvider = ({ children }) => {
  const { show: showPopup, data: message, handleShow: handleShowPopup, handleClose: handleClosePopup } = useTogglePopup()

  const [tab, setTab] = useState(TAB_INTRO);
  const [loading, setLoading] = useState(false)
  const [transactionId, setTransactionId] = useState(null)
  const [sessionIdFront, setSessionIdFront] = useState(null)
  const [sessionIdBack, setSessionIdBack] = useState(null)
  const [sessionIdFace, setSessionIdFace] = useState(null)
  const [processId, setProcessId] = useState(null)
  const [cardInfo, setCardInfo] = useState(null)
  const [imgCardFront, setImgCardFront] = useState(null)
  const [imgCardBack, setImgCardBack] = useState(null)
  const [imgFace, setImgFace] = useState(null)

  const checkDataValid = useCallback((error)=> {
    // const message = error.msg
    if (error.code === 4030) {
      handleShowPopup("Phiên làm việc hết hạn")
      return false
    } else if (error.code === 4027) {
      handleShowPopup("Đã vượt quá số lần xác thực, vui lòng thử lại")
      return false
    }
    return true
  }, [handleShowPopup])
  const value = useMemo(
    () => ({
      tab, setTab,
      transactionId, setTransactionId,
      sessionIdFront, setSessionIdFront,
      sessionIdBack, setSessionIdBack,
      sessionIdFace, setSessionIdFace,
      processId, setProcessId,
      cardInfo, setCardInfo,
      imgCardFront, setImgCardFront,
      imgCardBack, setImgCardBack,
      imgFace, setImgFace,
      loading, setLoading,
      handleShowPopup, handleClosePopup,
      checkDataValid
    }),
    [transactionId,
      sessionIdFront,
      sessionIdBack,
      sessionIdFace,
      processId,
      cardInfo,
      imgCardFront,
      imgCardBack,
      imgFace,
      loading,
      handleShowPopup,
      handleClosePopup,
      checkDataValid,
      tab
    ]
  )

  return (
    <GlobalCtx.Provider value={value}>
      {children}
      <Loading isShow={loading} />
      <Popup
        isShow={showPopup}
        onClose={handleClosePopup}
        classWrap="fclould-ekyc ekyc-popup__main"
      >
        <div className='ekyc-popup__confirm  flex flex-col items-center justify-center'>
          <p className='ekyc-popup__confirm__title'>Thông báo</p>
          <span>{message} </span>
          <Button text='Thoát' mt={23} onClick={() => {
            window?.location?.reload()
            handleClosePopup()
          }} />
        </div>
      </Popup>
    </GlobalCtx.Provider>
  )
}
