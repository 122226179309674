import * as yup from "yup";

const checkEmoji = value => !/\p{Extended_Pictographic}/ug.test(value)
const mesValidateEmoji = 'Vui Lòng không nhập ký hiệu emoji '


export const validateRequired = (label= "thông tin") => yup
    .string()
    .required(`Vui lòng nhập ${label}`)
    // .matches(/^[\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]/,
    //     "Kí tự không cho phép nhập"
    // )


export const validateNumberRequired = (label = "thông tin") => yup
    .number()
    .typeError(`Thông tin ${label} phải là số`)
    .required(`Vui lòng nhập ${label}`)
    


export const validateName = yup
    .string()
    .required("Vui lòng nhập họ tên")
    .max(30, "Họ tên tối đa 30 ký tự")
    // .matches(/^[\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]/,
    //     "Kí tự không cho phép nhập"
    // )

export const validateUsername = yup
    .string()
    .required("Vui lòng nhập Username")
    .max(15, "Username tối đa 15 ký tự")
    .matches(
        /^[A-Za-z0-9]*$/,
        "Username gồm số và chữ, không phân biệt chữ hoa và chữ thường, không sử dụng ký tự đặc biệt"
    )



export const validateEmail = yup
    .string()
    .required("Vui lòng nhập Email")
    .max(40, "Email tối đa 40 ký tự")
    .email("Email không đúng định dạng")

export const validatePhone = yup
    .string()
    .max(15, "SDT tối đa 15 ký tự!")
    // .notRequired()
    .required("Vui lòng nhập số điện thoại")
    .matches(/^(84|0[3|5|7|8|9])[0-9]{8}$/, {
        message: "Số điện thoại chưa đúng định dạng", excludeEmptyString: true
    })
    

export const validatePassword = yup
    .string()
    .required(`Mật khẩu không được để trống.`)
    .max(40, "Mật khẩu tối đa 40 ký tự")
    .min(6, "Mật khẩu tối thiểu 6 ký tự")
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,40})/,
        "Mật khẩu phải có từ 6 đến 32 ký tự, có ký tự chữ số, chữ hoa và chữ thường."
    )

export const validateConfirmPassword = (field = "newPassword") => {
    return yup
        .string()
        .required(`Xác nhận mật khẩu không được để trống.`)
        .max(40, "Mật khẩu tối đa 40 ký tự")
        .min(6, "Mật khẩu tối thiểu 6 ký tự")
        .oneOf([yup.ref(field), null], "Mật khẩu nhập lại không khớp")
};

export const validateCheckbox = yup.boolean().oneOf([true], 'Vui lòng chọn đồng ý các điều kiện!');

export const validateSelect = (label)=>{
    return yup
        .mixed()
        .required(`Vui lòng chọn ${label}`)
}



