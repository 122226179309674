/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGlobalContext } from '../../contexts/global'
import useMounted from '../../hooks/useMounted'
import useTogglePopup from '../../hooks/useTogglePopup'
import { toastError } from '../../utils/toast'
import Button from '../common/Button'
import Camera from '../common/Camera'
import { ListNote, NoteError } from '../common/Note'
import Popup from '../common/Popup'
import Title from '../common/Title'
import ArrowLiveliness from './ArrowLiveliness'

const VIDEO_CONSTRAINTS = {
  width: 960,
  height: 960,
  facingMode: {
    exact: 'user'
  }
};


const LIST_STEP = [
  {
    action: 'left',
    title: 'Vui lòng quay mặt từ từ sang trái'
  },
  {
    action: 'down',
    title: 'Vui lòng quay mặt từ từ xuống dưới'
  },
  {
    action: 'right',
    title: 'Vui lòng quay mặt từ từ sang phải'
  },
  {
    action: 'top',
    title: 'Vui lòng quay mặt từ từ lên trên'
  },
  {
    action: null,
    title: 'Căn chỉnh khuôn mặt của bạn vào giữa khung và nhìn thẳng'
  },
]

const MAX_TIME = 60

export default function CheckLiveliness({ onSuccess }) {
  const listStep = LIST_STEP

  //   const listStep = useMemo(() => {
  //   return [...[...LIST_STEP.slice(0, LIST_STEP.length - 1)].sort(() => Math.random() - 0.5), LIST_STEP[LIST_STEP.length - 1]]
  // }, [])

  const indexStep = useRef(0)
  const cameraRef = useRef()
  const checkLivenessInterval = useRef(null)

  const [currentStep, setCurrentStep] = useState(listStep[indexStep.current])
  const { show: showPopupWarning, data: messageWarning, handleShow: handleShowPopupWarning, handleClose: handleClosePopupWarning } = useTogglePopup()
  const [err, setErr] = useState('')
  const { setSessionIdFace, setImgFace, sessionIdFace, setLoading, checkDataValid } = useGlobalContext()
  const [imgSrc, setImgSrc] = useState(null);
  const [isPlayingLiveness, setIsPlayingLiveness] = useState(true)
  const [isPlayingCheckFace, setIsPlayingCheckFace] = useState(false)
  const [timeRemain, setTimeRemain] = useState(MAX_TIME)
  const { isMounted } = useMounted()

  const bgCamera = useMemo(() => {
    let list = ['images/bg-round-gray-liveliness.png']
    listStep.slice(0, indexStep.current).forEach(({ action }) => {
      list.push(`images/bg-round-${action}.png`)
    })
    return list
  }, [indexStep.current, listStep])


  const handleNextStep = useCallback(() => {
    setErr('')
    indexStep.current = indexStep.current + 1
    setCurrentStep(listStep[indexStep.current])
    if (indexStep.current === LIST_STEP.length - 1) {
      clearInterval(checkLivenessInterval.current)
      setIsPlayingCheckFace(true)
    }
  }, [])

  const checkFace = useCallback(async () => {
    setLoading(true)
    if (!isPlayingCheckFace) return;
    const imgSrc = cameraRef.current.takePicture();
    const blobImage = await fetch(imgSrc).then(res => res.blob());
    cameraRef.current.pause()
    const reCheck = () => {
      setTimeout(() => { checkFace() }, 2000)
    }
    try {
      const { data } = await window.EKYC.uploadPhoto(blobImage, 'face')
      // const data = await new Promise((resolve, reject) => setTimeout(() => { resolve({
      //   session_id: 'xxx'
      // }) }, 1000))
      if (data.session_id) {
        setSessionIdFace(data.session_id)
        setImgFace(imgSrc)
      } else {
        // reCheck()
      }
    } catch (error) {
      checkDataValid(error)
      setErr(error.msg || error.message)
      // reCheck()
    }
    cameraRef.current.play()
    setLoading(false)

  }, [isPlayingCheckFace])

  useEffect(() => {
    // setTimeout(() => { checkFace() }, 2000)
  }, [checkFace])

  const checkLiveness = useCallback(async () => {
    const imgSrc = cameraRef.current.takePicture();
    const blobImage = await fetch(imgSrc).then(res => res.blob());
    try {
      // await new Promise((resolve, reject) => setTimeout(() => { resolve(null) }, 1000))
      // return true;
      const action = listStep[indexStep.current].action
      // setErr(indexStep.current)
      const { code } = await window.EKYC.checkLiveness(blobImage, action)
      if (code === 0) return true;
      else {
        // toastError(msg)
        // setErr(msg)
        return false
      }

    } catch (error) {
      const message = error.msg
      // setErr(message)
    }
  }, [])

  // const getIndexStep = use

  const autoHandleLiveness = useCallback(async () => {
    let indexOld = indexStep.current
    let valid = await checkLiveness()
    if (!valid || indexStep.current !== indexOld) return;
    handleNextStep()
  }, [])

  // tự động gửi request sau 1 khoảng thời gian
  useEffect(() => {
    if (!isPlayingLiveness) return;
    checkLivenessInterval.current = setInterval(() => {
      autoHandleLiveness()
    }, [200])
    return () => {
      clearInterval(checkLivenessInterval.current)
    }
  }, [isPlayingLiveness])

  // countdown thời gian kết thúc
  useEffect(() => {
    if (timeRemain <= 0) {
      handleShowPopupWarning(`Xác thực khuôn mặt vượt quá ${MAX_TIME}s, vui lòng xác thực lại.`)
      setIsPlayingLiveness(false)
      setIsPlayingCheckFace(false)
      return;
    };
    let timeInterval = setInterval(() => {
      setTimeRemain(x => x - 1)
    }, 1000)
    return () => clearInterval(timeInterval)
  }, [timeRemain])

  const handleResetLiveness = useCallback(() => {
    setTimeRemain(MAX_TIME)
    indexStep.current = 0
    setCurrentStep(listStep[0])
    handleClosePopupWarning()
    setIsPlayingLiveness(true)
  }, [])

  // chạy sau khi liveness thành công
  useEffect(() => {
    if (isMounted && sessionIdFace) onSuccess && onSuccess()
  }, [sessionIdFace])

  return (
    <>
      <div className='ekyc-liveliness-wrap flex flex-col items-center'>
        <Title text='Xác thực khuôn mặt' />
        {/* <span className='text-xl mb-6'>Xác thực khuôn mặt</span> */}
        {!err ?
          <ListNote warnings={[
            'Khách hàng tiến hành hướng khuôn mặt vào chính giữa khung hình. Sau đó quay sang Trái, quay sang Phải và quay về Giữa.',
          ]} />
          :
          <NoteError text={err} />
        }
        {/* <NoteError text={err} /> */}
        <div className='ekyc-camera-card flex flex-col items-center'>
          {/* <Button text='check' onClick={autoHandleLiveness} /> */}
          <Camera
            videoConstraints={VIDEO_CONSTRAINTS}
            imgPreview={imgSrc}
            ref={cameraRef}
            bgCamera={bgCamera}
            flip={true}
          />
          <div className='ekyc-row-button'>

          </div>
          <div className='flex flex-col items-center'>
            {currentStep?.action && <ArrowLiveliness direction={currentStep?.action} />}
            {isPlayingCheckFace && <>
              <div className='ekyc-row-button' style={{ marginTop: -25 }}>
                <Button text='Chụp' onClick={checkFace} />
              </div>
            </>}
            <p className='font-bold text-xl warning-note__text mt-0 text-center' style={{ margin: 12 }}>{currentStep?.title}</p>
            <p className='mt-0 warning-note__text w-full' style={{ textAlign: 'right' }}>Còn lại: {timeRemain}s</p>
          </div>


        </div>
      </div>
      <Popup
        isShow={showPopupWarning}
        onClose={handleResetLiveness}
      >
        <div className='ekyc-popup__confirm flex flex-col items-center justify-center'>
          <p className='ekyc-popup__confirm__title'>Thông báo</p>
          <span>{messageWarning} </span>
          <Button text='Đóng' mt={23} onClick={handleResetLiveness} />
        </div>
      </Popup>
    </>

  )
}
