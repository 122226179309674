import React from 'react'

export default function Note({ text = '' }) {
    return (
        <li className='flex items-center warning-note'>
            <img src='images/icons/warning.svg' alt='warning-icon' className='warning-note__icon' style={{width: 24, height: 24}} />
            <span className='warning-note__text text-sm'>{text}</span>
        </li>
    )
}

export function ListNote({ warnings }) {
    return (
        <ol className='list-warning'>
            {warnings.map((item, index) => (
                <Note text={item} key={`${item}-${index}`} />
            ))}
        </ol>
    )
}


export function NoteError({ text = '' }) {
    return (
        <p className='flex items-center mt-0 mb-0'>
            {text && <img src='images/icons/error.svg' alt='error-icon' className='error-note__icon' style={{width: 24, height: 24}} />}
            <span className='error-note__text text-sm'>{text}</span>
        </p>
    )
}
