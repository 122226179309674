import { useRef } from "react";

export default function Popup({
    isShow,
    onClose,
    children,
    allowClickBackground = true,
    classWrap = "ekyc-popup__main"
}) {
    const ref = useRef(null);
    const handleClickBackground = (event) => {
        if (allowClickBackground && event.target === ref.current) {
            onClose && onClose();
        }
    }
    return isShow && (
        <div ref={ref} className="ekyc-popup flex justify-center items-center" onClick={handleClickBackground}>
            <div className={classWrap}>
                {children}
            </div>
        </div>
    );
}
