import React, { useCallback } from 'react'

export default function Button({ text = 'Text', disable = false, width, bg, color, radius = 8, onClick, border, className = '', mt, ...rest }) {
    const handleClick = useCallback(() => {
        onClick && onClick()
    }, [onClick])
    return (
        <button
            className={`ekyc-button ${disable ? 'disable' : ''} ${className}`}
            style={{
                minWidth: width,
                backgroundColor: bg,
                borderRadius: radius,
                borderColor: border,
                color,
                marginTop: mt
            }}
            onClick={handleClick}
            {...rest}

        >
            {text}
        </button >
    )
}
