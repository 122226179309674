import { toast } from 'react-toastify'

export const toastSuccess = (title, body = {}, timeout = 3000) => {
  toast.success(title, {
    autoClose: timeout,
    position: 'top-right',
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    ...body
  })
}

export const toastError = (title, body = {}, timeout = 3000) => {
  toast.error(title, {
    autoClose: timeout,
    position: 'top-right',
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    ...body
  })
}

export const toastWarning = (title, body = {}, timeout = 3000) => {
  toast.warning(title, {
    autoClose: timeout,
    position: 'top-right',
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    ...body
  })
}
