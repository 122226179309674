import React from 'react'
import Title from '../common/Title'

export default function ResultEkyc({ err }) {

  return (
    <div className='ekyc-result-wrap flex flex-col items-center'>
      <Title text='Kết quả' />
      {
        err
          ?
          <img src="images/icons/fail.svg" alt='icon error' className='ekyc-result__image' />
          :
          <img src="images/icons/success.svg" alt='icon success' className='ekyc-result__image' />
      }
      <span className='ekyc-result__message'>{err || "Yêu cầu xác thực của bạn đã được gửi thành công, vui lòng chờ kết quả phê duyệt!"}</span>
    </div>
  )
}
