/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import CheckCardBack from '../../components/CheckCardBack';
import CheckCardFront from '../../components/CheckCardFront';
import CheckLiveliness from '../../components/CheckLiveliness';
import FormSubmit from '../../components/FormSubmit';
import Intro from '../../components/Intro';
import ImagePreview from '../../components/PreviewImage/ImagePreview';
import ResultEkyc from '../../components/ResultEkyc';
import { TAB_BACK, TAB_FRONT, TAB_INTRO, TAB_LIVELINESS, TAB_PREVIEW, TAB_RESULT, TAB_SUBMIT } from '../../contants/tab';
import { useGlobalContext } from '../../contexts/global';


export default function Home() {
  const [errMessage, setErrorMessage] = useState('')
  const { processId, tab, setTab, imgFace } = useGlobalContext()

  const handleChangeTab = useCallback((tab) => {
    if (imgFace) setTab(TAB_PREVIEW)
    else setTab(tab)
  }, [imgFace])


  return (
    <div className='wrap-fclould-ekyc'>
      <div className='fclould-ekyc'>
        {tab === TAB_INTRO && <Intro onSuccess={() => handleChangeTab(TAB_FRONT)} />}
        {tab === TAB_FRONT && <CheckCardFront onSuccess={() => handleChangeTab(TAB_BACK)} />}
        {tab === TAB_BACK && <CheckCardBack onSuccess={() => handleChangeTab(TAB_LIVELINESS)} />}
        {/* {tab === TAB_LIVELINESS && <CheckFace onSuccess={handleShowResult} />} */}
        {tab === TAB_LIVELINESS && <CheckLiveliness onSuccess={() => handleChangeTab(TAB_PREVIEW)} />}
        {tab === TAB_PREVIEW && <ImagePreview onSuccess={() => setTab(TAB_SUBMIT)} />}
        {tab === TAB_SUBMIT && <FormSubmit onSuccess={() => setTab(TAB_RESULT)} />}
        {tab === TAB_RESULT && <ResultEkyc err={errMessage} onSuccess={() => { }} />}
      </div>
    </div>
  )
}
