import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const InputSelect = ({ name, control, label, help, error, wrapperClassname, required, options, isSearchable = true, isClearable = false, ...rest }) => {
  // const [disabled, setDisabled] = useState(true)
  // const inputRef = useClickOutSide(() => {
  //     setDisabled(true)
  // })

  useEffect(() => {

  }, [])

  const computeCurrentValue = useCallback((value) => {
    return options.find(option => option.value === value)
  }, [options])
  return (
    <div
      className={classNames(
        'ekyc-form-item',
        error && 'has-error',
        wrapperClassname
      )}
    // ref={inputRef}
    >
      <label>
        {label && (
          <div className="ekyc-form__lable">
            {label} {required && <span className="text-danger">*</span>}
          </div>
        )}
        <div className="ekyc-form__input-select relative w-full">
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <Select
                  className="custom-select"
                  // getOptionLabel={option => option.name}
                  // getOptionValue={option => option.name}
                  options={options}
                  placeholder={`Nhập ${label?.toLocaleLowerCase()}`}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  onChange={(option) => { onChange(option.value) }}
                  value={computeCurrentValue(value)}
                  onBlur={onBlur}
                  {...rest}
                />
              )
            }}
          />
          {/* <div>
                        <img onClick={() => { setDisabled(false) }} src='images/icons/calendar_light.svg' alt='edit icon' className='ekyc-form__input-date__icon' />
                    </div> */}
        </div>
        {error && <div className="ekyc-form__text-error">{error?.message || error}</div>}
      </label>
    </div>
  )
}

export default InputSelect
