import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { validateName, validateRequired } from '../../contants/validate'
import { useGlobalContext } from '../../contexts/global'
import useTogglePopup from '../../hooks/useTogglePopup'
import { toastError } from '../../utils/toast'
import Button from '../common/Button'
import InputDate from '../common/Form/InputDate'
import InputSelect from '../common/Form/InputSelect'
import InputText from '../common/Form/InputText'
import InputTextArea from '../common/Form/InputTextArea'
import Popup from '../common/Popup'
import ImagePopup from "../common/Popup/ImagePopup"
import Title from '../common/Title'
import ImagePreview from "../PreviewImage/ImagePreview"

// const CARD_INFO = {
//   "id": "033195007863",
//   "birth_day": "29/07/1995",
//   "birth_place": "",
//   "card_type": "CĂN CƯỚC CÔNG DÂN",
//   "gender": "Nữ",
//   "issue_date": "01/06/2021",
//   "issue_place": "CỤC TRƯỞNG CỤC CẢNH SÁT QUẢN LÝ HÀNH CHÍNH VỀ TRẬT TỰ XÃ HỘI",
//   "name": "NGUYỄN THỊ THÙY DƯƠNG",
//   "nationality": "Việt Nam",
//   "origin_location": "Hưng Đạo, Tiên Lữ, Hưng Yên",
//   "passport_no": "",
//   "recent_location": "Thôn Muội Sảng\\nHưng Đạo, Tiên Lữ, Hưng Yên",
//   "valid_date": "29/07/2035",
//   "feature": "Nốt ruồi C: 2cm trên sau mép phải",
//   "nation": "",
//   "religion": "",
//   "mrz": "IDVNM1950078633033195007863<<5\\n9507292F3507290VNM<<<<<<<<<<<0\\nNGUYEN<<THI<THUY<DUONG<<<<<<<<"
// }


export default function FormSubmit({ onSuccess }) {
  // const cardInfo = CARD_INFO
  // const { imgCardFront, imgCardBack, imgFace, setLoading } = useGlobalContext()
  const { imgCardFront, imgCardBack, cardInfo, imgFace, setLoading } = useGlobalContext()
  const [genderOptions, setGenderOptions] = useState([])
  const [nationalOptions, setNationalOptions] = useState([])
  const { show: showPopupWarning, data: messageWarning, handleShow: handleShowPopupWarning, handleClose: handleClosePopupWarning } = useTogglePopup()
  const { show: showPopupImage, data: indexInit, handleShow: handleShowPopupImage, handleClose: handleClosePopupImage } = useTogglePopup()
  const LIST_IMAGE = useMemo(() => {
    return [
      {
        url: imgCardFront || 'images/card-front.png',
        name: 'card font'
      },
      {
        url: imgCardBack || 'images/card-back.png',
        name: 'card back'
      },
      {
        url: imgFace || 'images/bgCheckFace.png',
        name: 'face'
      }
    ]
  }, [imgCardFront, imgCardBack, imgFace])

  useEffect(() => {
    const getGender = async () => {
      let genders = await window.EKYC.getGender()
      if (genders?.data) genders = genders.data
      genders && setGenderOptions(genders.map(option => ({ label: option.name, value: option.name })))
    }

    const getNational = async () => {
      let nationals = await window.EKYC.getNational()
      if (nationals?.data) nationals = nationals.data
      setNationalOptions(nationals.map(option => ({ label: option.name, value: option.name })))
    }
    if (!window.EKYC) return;
    getGender()
    getNational()
  }, [])

  const schema = yup
    .object({
      name: validateName,
      id: validateRequired('id'),
      issue_place: validateRequired('issue_place'),
      valid_date: validateRequired('valid_date'),
      birth_day: validateRequired('birth_day'),
      gender: validateRequired('gender'),
      nationality: validateRequired('nationality'),
    })

  const { register, handleSubmit, control, formState: { errors, dirtyFields }, setValue, watch, reset, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...cardInfo
    }
  });

  const onSubmit = useCallback(async (data) => {
    let payload = { ...cardInfo, ...data }
    try {
      setLoading(true)
      const { data } = await window.EKYC.submitInfo(payload)
      if (data) onSuccess && onSuccess()

    } catch (error) {
      const message = error.msg
      handleShowPopupWarning(message)
      // toastError(message)
    }
    setLoading(false)

    // handleShowPopupWarning('Đã vượt quá số lần xác thực, vui lòng chờ đến 13/6/2023 10:30 để thử lại.')
  }, [dirtyFields])


  return (
    <>
      <div className='ekyc-submit-wrap flex flex-col items-center'>
        <Title text='Thông tin của bạn' style={{ fontWeight: 600 }} />
        <div className='ekyc-submit__main'>
          <div className='ekyc-submit__form'>
            <h3 className='font-bold text-xl mt-0' style={{ marginBottom: 24 }}>Giấy tờ: {cardInfo?.card_type}</h3>
            {/* <div>
            <p>Ảnh đã chụp</p>
            <div className="w-full flex">
              {LIST_IMAGE.map(({ url, name }, index) => (
                <img src={url} alt={name} className='' style={{width: "30%"}} key={url + name} onClick={() => handleShowPopupImage(index)} />
              ))}
            </div>
          </div> */}
            <form id="ekyc-form-submit" onSubmit={handleSubmit(onSubmit)}>
              <div >
                <InputText
                  register={register('name')}
                  required
                  label='Họ và tên'
                  error={errors.name}
                />

                <InputText
                  register={register('id')}
                  required
                  label='Số giấy tờ'
                  error={errors.id}
                />


                <InputDate
                  control={control}
                  name="birth_day"
                  required
                  label='Ngày sinh'
                  error={errors.birth_day}
                />


                <InputSelect
                  control={control}
                  name="gender"
                  required
                  label='Giới tính'
                  error={errors.gender}
                  options={genderOptions}
                />

                <InputSelect
                  control={control}
                  name="nationality"
                  required
                  label='Quốc tịch'
                  error={errors.nationality}
                  options={nationalOptions}
                />

                <InputText
                  register={register('origin_location')}

                  required
                  label='Quê quán'
                  error={errors.origin_location}
                />

                <InputTextArea
                  register={register('recent_location')}
                  required
                  label='Địa chỉ thường chú'
                  error={errors.recent_location}
                />

                <InputDate
                  control={control}
                  name="valid_date"
                  required
                  label='Ngày hết hạn'
                  error={errors.valid_date}
                />

                <InputDate
                  control={control}
                  name="issue_date"
                  required
                  label='Ngày cấp giấy tờ'
                  error={errors.issue_date}
                />

                <InputTextArea
                  rows={2}
                  register={register('feature')}
                  required
                  label='Đặc điểm nhận dạng'
                  error={errors.feature}
                />


                <InputTextArea
                  rows={3}
                  register={register('issue_place')}
                  required
                  label='Nơi cấp giấy tờ'
                  error={errors.issue_place}
                />

                <InputTextArea
                  rows={4}
                  register={register('mrz')}
                  required
                  label='MRZ'
                  error={errors.mrz}
                />



              </div>
            </form>
          </div>
          <div className='ekyc-submit__image'>
            {LIST_IMAGE.map(({ url, name }, index) => (
              <img src={url} alt={name} className='ekyc-submit__image__item' key={url + name} onClick={() => handleShowPopupImage(index)} />
            ))}
          </div>
        </div>
        <div>
          <Button type="submit" text='Hoàn tất' form="ekyc-form-submit" mt={35} />
        </div>
      </div>

      <ImagePopup
        isShow={showPopupImage}
        // isShow={true}
        indexInit={indexInit}
        onClose={handleClosePopupImage}
        // listImage={[imgCardFront, imgCardBack, imgFace]}
        listImage={LIST_IMAGE.map(item => item.url)}
      />

      <Popup
        isShow={showPopupWarning}
        onClose={handleClosePopupWarning}
      >
        <div className='ekyc-popup__confirm flex flex-col items-center justify-center'>
          <p className='ekyc-popup__confirm__title'>Thông báo</p>
          <span>{messageWarning} </span>
          <Button text='Đóng' mt={23} onClick={handleClosePopupWarning} />
        </div>
      </Popup>
    </>

  )
}
