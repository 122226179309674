import React from 'react'
import { useMemo } from 'react'
const LIST_DIRECTION = {
  left: {
    deg: 90,
    margin: '1px 0'
  },
  right:  {
    deg: 270,
    margin: '-3px 0'
  },
  down:  {
    deg: 0,
    margin: '0 2px'
  },
  top:  {
    deg: 180,
    margin: '0 -2px'
  },
}


// export function Arrow({direction}) {
//   const style = useMemo(() => {
//     const roateDeg = LIST_DIRECTION[direction] || LIST_DIRECTION.left
//     return {transform: `rotate(${roateDeg}deg)`}
//   }, [direction])
//   return (
//     <div class="arrow-liveliness" style={style}>
//       <span></span>
//       <span></span>
//       <span></span>
//     </div>

//   )
// }


export default function CheckLiveliness({ direction }) {
  const style = useMemo(() => {
    const style = LIST_DIRECTION[direction] ?? LIST_DIRECTION.left
    return { transform: `rotate(${style.deg}deg) translate(-50%, -50%)`, margin: style.margin }
  }, [direction])
  return (
    <div className='wrap-arrow-liveliness'>
      <div className="arrow-liveliness" style={style}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
