import classNames from 'classnames'
import { useState } from 'react'
import useClickOutSide from '../../../../hooks/useClickOutSide'



const InputTextArea = ({ register, label, help, error, wrapperClassname, required, ...rest }) => {
  const [disabled, setDisabled] = useState(true)
  const inputRef = useClickOutSide(() => {
    setDisabled(true)
  })
  return (
    <div
      className={classNames(
        'ekyc-form-item',
        error && 'has-error',
        wrapperClassname
      )}
      ref={inputRef}
    >
      <label>
        {label && (
          <div className="ekyc-form__lable">
            {label} {required && <span className="text-danger">*</span>}
          </div>
        )}
        <div className="ekyc-form__input-text relative w-full">
          <textarea
            type="text"
            className='ekyc-form__input-text__content w-full'
            {...register}
            placeholder={"Nhập " + label?.toLocaleLowerCase()}
            disabled={disabled}
            {...rest}
          />
          <div>
            <img onClick={() => { setDisabled(false) }} src='images/icons/edit_light.svg' alt='edit icon' className='ekyc-form__input-text__icon' />
          </div>
        </div>
        {error && <div className="ekyc-form__text-error">{error?.message || error}</div>}
      </label>
    </div>
  )
}

export default InputTextArea
