import React, { useCallback, useRef, useState } from 'react';
import Camera from '.';
import Button from '../Button';

const VIDEO_CONSTRAINTS = {
    width: 960,
    height: 960,
    facingMode: {
        exact: 'environment'
    }
};

export default function CameraCard({ allowNextStep, onPlay, onSuccess, ...rest }) {
    const [imgSrc, setImgSrc] = useState(null);
    const cameraRef = useRef()
    const takePicture = () => {
        const dataUrl = cameraRef.current.takePicture();
        setImgSrc(dataUrl)
    }
    const reTakePicture = useCallback(() => {
        setImgSrc(null)
        onPlay && onPlay()
    }, [])

    return (
        <div className='ekyc-camera-card flex flex-col items-center'>

            <Camera
                videoConstraints={VIDEO_CONSTRAINTS}
                imgPreview={imgSrc}
                ref={cameraRef}
                bgCamera='images/frameCard.png'
                {...rest}
            />
            <div className='ekyc-row-button'>
                {
                    !imgSrc
                        ? <Button text='Chụp' onClick={takePicture} />
                        :
                        <>
                            <Button text='Chụp lại' onClick={reTakePicture} />
                            {allowNextStep && <Button text='Tiếp tục' onClick={() => { onSuccess && onSuccess(imgSrc) }} />}
                        </>
                }
            </div>
            {/* <Button text='test' disable={false} onClick={test} /> */}

        </div>
    )
}
