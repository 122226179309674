// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const hasGetUserMedia = () => {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}
export const CameraCtx = createContext()
export const useCameraContext = () => useContext(CameraCtx)

/* eslint-disable react-hooks/exhaustive-deps */
export const CameraProvider = ({ children }) => {
  const [isValidCam, setIsValidCam] = useState(false);
  const [err, setErr] = useState('')

  const handleErrCam = useCallback((err) => {
    setErr(err);
    setIsValidCam(!err)
  }, [])

  const requireAccess = useCallback(async () => {
    let errMessage = 'Camera chưa được cấp quyền hoặc không có camera!'
    if (!hasGetUserMedia) {
      handleErrCam('Camera không được hỗ trợ!')
      return false;
    }
    const requestAccess = await navigator.mediaDevices.getUserMedia({ video: true }).catch((err) => {

      if(err.message?.includes('permission')) errMessage = 'Không có quyền truy cập camera!';
      else if(err.message?.includes('device not found')) errMessage = 'Không tìm thấy camera!'
      else errMessage = 'Camera không khả dụng';
      handleErrCam(errMessage)
      return false;
    })
    if (!requestAccess) return false;
    handleErrCam()
    return true;
  }, [])


  useEffect(() => {
    requireAccess()
  }, [])


  const value = useMemo(
    () => ({
      requireAccess, isValidCam, setIsValidCam, err
    }),
    [requireAccess, isValidCam, setIsValidCam, err]
  )

  return (
    <CameraCtx.Provider value={value}>
      {children}
    </CameraCtx.Provider>
  )
}
