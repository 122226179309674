import classNames from 'classnames';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from 'react-hook-form';
import useClickOutSide from '../../../../hooks/useClickOutSide';
dayjs.extend(customParseFormat)
const convertLocalToUTCDate = (date) => {
    if (!date) {
        return date;
    }
    const dateFormat = dayjs(date).format('DD/MM/YYYY')
    return dateFormat
}
const InputDate = ({ name, control, label, help, error, wrapperClassname, required, ...rest }) => {
    const [disabled, setDisabled] = useState(true)
    const inputRef = useClickOutSide(() => {
        setDisabled(true)
    })

    useEffect(()=>{

    }, [])
    return (
        <div
            className={classNames(
                'ekyc-form-item',
                error && 'has-error',
                wrapperClassname
            )}
            ref={inputRef}
        >
            <label>
                {label && (
                    <div className="ekyc-form__lable">
                        {label} {required && <span className="text-danger">*</span>}
                    </div>
                )}
                <div className="ekyc-form__input-date relative w-full">
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <DatePicker
                                ref={ref}
                                id={name}
                                className={``}
                                placeholderText="dd/MM/yyyy"
                                onChange={(date) => onChange(convertLocalToUTCDate(date))}
                                selected={dayjs(value, "DD/MM/YYYY").toDate()}
                                onBlur={onBlur}
                                dateFormat="dd/MM/yyyy"
                            // minDate={minDate}
                            // maxDate={maxDate}
                            />
                        )}
                    />
                    <div>
                        <img src='images/icons/calendar_light.svg' alt='edit icon' className='ekyc-form__input-date__icon' />
                    </div>
                </div>
                {error && <div className="ekyc-form__text-error">{error?.message || error}</div>}
            </label>
        </div>
    )
}

export default InputDate
