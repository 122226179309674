/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { isMobile } from '../../../utils';
import { toastError } from '../../../utils/toast';
import Button from '../Button';

const VIDEO_CONSTRAINTS_DEFAULT = {
  width: { idle: 1080 },
  height: { idle: 1920 }
};
const Camera = forwardRef(({ videoConstraints, bgCamera, imgPreview, children, flip = false, ...rest }, ref) => {
  // const [device, setDevice] = useState({});
  // const [devices, setDevices] = useState([]);
  // const [streaming, setSteaming] = useState(false)
  const videoRef = useRef(null)

  const takePicture = useCallback(() => {
    // const canvas = canvasRef.current
    // alert('takePicture')
    const canvas = document.createElement("canvas");
    const video = videoRef.current
    if (!canvas || !video || !video.videoHeight) return;
    const { clientWidth, clientHeight, videoWidth, videoHeight } = video
    if ((clientWidth / clientHeight) > (videoWidth / videoHeight)) {
      canvas.width = videoWidth;
      canvas.height = (clientHeight / clientWidth) * videoWidth;
    } else {
      canvas.height = videoHeight;
      canvas.width = (clientWidth / clientHeight) * videoHeight;
    }
    // alert(JSON.stringify({ clientWidth, clientHeight, videoWidth, videoHeight, canvasWidth: canvas.width, canvasHeight: canvas.height }))
    const context = canvas.getContext("2d");
    context.drawImage(video, -(videoWidth - canvas.width) / 2, -(videoHeight - canvas.height) / 2);


    const imgSrc = canvas.toDataURL("image/png");
    // var i = new Image();
    // i.onload = function () {
    //   alert(i.width + ", " + i.height);
    //   window.open(i.src)
    // };
    // i.src = imgSrc;

    return imgSrc
  }, [])




  const startStream = async (constraints) => {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    // let stream_settings = stream.getVideoTracks()[0].getSettings();
    // alert('Width: ' + stream_settings.width + 'px' + ', Height: ' + stream_settings.height + 'px');
    try {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (err) {
    }
    // setSteaming(true)

  };


  const setupCam = useCallback(async () => {

    if (!navigator?.mediaDevices?.getUserMedia) {
      toastError('Thiết bị không hỗ trợ camera')
      return;
    }

    // yêu cầu quyền
    // const requestAccess = await navigator.mediaDevices.getUserMedia({ video: true }).catch((err) => {
    //     errMessage = err.message
    //     return null;
    // })
    // if (!requestAccess) {
    //     alert(errMessage)
    //     return;
    // };

    let listDevice = await navigator.mediaDevices.enumerateDevices();
    listDevice = listDevice.filter(device => device.kind === 'videoinput')
    if (!listDevice || listDevice.length === 0) {
      toastError('không tìm thấy camera')
      return;
    }
    // setDevices(listDevice)
    // let device = listDevice[1]
    // setDevice(device)
    // alert(getJson(device))

    let videoConstraintsCustom = {
      ...VIDEO_CONSTRAINTS_DEFAULT,
      ...videoConstraints
      // deviceId: {
      //   exact: device.deviceId
      // }
    };
    if (!isMobile()) {
      delete videoConstraintsCustom.facingMode;
    }
    // alert(JSON.stringify(videoConstraintsCustom))
    startStream({ video: videoConstraintsCustom });

  }, [videoConstraints])


  useEffect(() => {
    if (!imgPreview) setupCam()
  }, [imgPreview])




  useImperativeHandle(ref, () => ({
    takePicture,
    setupCam,
    play: () => videoRef.current?.play(),
    pause: () => videoRef.current?.pause(),
  }), [takePicture, setupCam])

  return (
    <>
      <div className='ekyc-camera relative'>
        {
          !imgPreview ? (
            <>
              <video
                muted
                playsInline
                controls={false}
                ref={videoRef}
                {...rest}
                className='ekyc-camera__video'
                style={{ transform: flip ? 'scaleX(-1)' : '' }}
              >Video stream not available.</video>
              {
                bgCamera && (Array.isArray(bgCamera) ? bgCamera : [bgCamera]).map(src => <img src={src} key={src} alt='Capture Camera' className='ekyc-camera__frame' />)
              }
            </>

          )
            : (
              <img src={imgPreview} alt='Capture Camera' className='ekyc-camera__image' />
            )
        }
      </div>
    </>
  )
})



export default Camera
