/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react'
import { useGlobalContext } from '../../contexts/global'
import { toastError } from '../../utils/toast'
import CameraCard from '../common/Camera/CameraCard'
import { ListNote, NoteError } from '../common/Note'
import Title from '../common/Title'


export default function CheckCardBack({ onSuccess }) {
  const [err, setErr] = useState('')
  const { setSessionIdBack, setImgCardBack, setLoading, checkDataValid } = useGlobalContext()

  const handleSubmit = useCallback(async (imgSrc) => {
    const blobImage = await fetch(imgSrc).then(res => res.blob());
    if (!window.EKYC) return;
    try {
      setLoading(true)
      const { data } = await window.EKYC.uploadPhoto(blobImage, 'back')
      if (data.session_id) {
        setImgCardBack(imgSrc)
        setSessionIdBack(data.session_id)
        onSuccess && onSuccess()
      }

    } catch (error) {
      checkDataValid(error)
      const message = error.msg
      // toastError(message)
      setErr(message)
    }
    setLoading(false)

  }, [])

  return (
    <div className='ekyc-back-wrap w-full flex flex-col items-center'>
      <Title text='Chụp mặt sau giấy tờ' />
      <span className='text-xl mb-6'>Vui lòng</span>
      {!err ?
        <ListNote warnings={[
          'Đặt giấy tờ nằm vừa khung hình chữ nhật, chụp đủ sáng và rõ nét',
        ]} />
        :
        <NoteError text={err} />
      }

      <CameraCard onSuccess={handleSubmit} allowNextStep={!err} onPlay={() => { setErr('') }} />
      {/* <div className='ekyc-row-button'>
                {showResetImage && <Button text='Chụp lại' disable={false} onClick={handleResetImage} />}
                <Button text='Tiếp tục' disable={false} onClick={onSuccess} />
            </div> */}
    </div>
  )
}
