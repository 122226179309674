import React, { useCallback, useMemo, useState } from 'react'
import { TAB_SUBMIT } from '../../contants/tab'
import { useGlobalContext } from '../../contexts/global'
import useTogglePopup from '../../hooks/useTogglePopup'
import Button from '../common/Button'
import ImagePopup from '../common/Popup/ImagePopup'
import Title from '../common/Title'


const PreviewImageItem = ({ image, onClick, error = '' }) => {

  return (
    <div className="w-full flex ekyc-preview__item">
      <img alt="preview card" src={image} className="ekyc-preview__image" onClick={onClick} />
      <div className="flex items-center ekyc-preview__status">
        {
          !error ?
            <div className="flex">
              <img alt="icon-success" src="images/icons/success.svg" width={16} height={16} />
              <span>Ảnh hợp lệ</span>
            </div> :
            <div className="flex">
              <img alt="icon-success" src="images/icons/error_circle.svg" width={16} height={16} />
              <span>{error}</span>
            </div>
        }

      </div>
    </div>
  )
}



export default function ImagePreview({ onSuccess }) {
  const { imgCardFront, imgCardBack, imgFace, setLoading, setProcessId, setCardInfo, checkDataValid } = useGlobalContext()
  const { show: showPopupImage, data: indexInit, handleShow: handleShowPopupImage, handleClose: handleClosePopupImage } = useTogglePopup()
  const [err, setErr] = useState(['', '', ''])
  const LIST_IMAGE = useMemo(() => {
    return [
      {
        url: imgCardFront || 'images/card-front.png',
        name: 'card font'
      },
      {
        url: imgCardBack || 'images/card-back.png',
        name: 'card back'
      },
      {
        url: imgFace || 'images/bgCheckFace.png',
        name: 'face'
      }
    ]
  }, [imgCardFront, imgCardBack, imgFace])

  const handleSubmit = useCallback(async (imgSrc) => {
    try {
      setLoading(true)
      const { data } = await window.EKYC.faceMatching()
      const { session_id, card_info } = data || {}
      setProcessId(session_id)
      setCardInfo(card_info)
      onSuccess && onSuccess()

    } catch (error) {
      checkDataValid(error)
      const message = error.msg
      let errNew = [...err]
      errNew[2] = 'Ảnh không khớp với giấy tờ mặt trước' || message
      setErr(errNew)
    }
    setLoading(false)
  }, [])



  return (
    <>
      <div className='ekyc-preview flex flex-col items-center'>
        <Title text='Ảnh đã chụp' style={{ fontWeight: 600 }} />
        {/* <div  className="ekyc-preview__title" style={{marginBottom: 24}}>Chụp 2 mặt giấy tờ</div> */}
        {LIST_IMAGE.map(({ url }, index) => <PreviewImageItem key={index} error={err[index]} image={url} onClick={() => handleShowPopupImage(index)} />)}

        <div>
          <Button type="submit" text='Tiếp tục' form="ekyc-form-submit" mt={35} onClick={handleSubmit} />
        </div>

      </div>
      <ImagePopup
        isShow={showPopupImage}
        // isShow={true}
        indexInit={indexInit}
        onClose={handleClosePopupImage}
        // listImage={[imgCardFront, imgCardBack, imgFace]}
        listImage={LIST_IMAGE.map(item => item.url)}
      />
    </>
  )
}
