import React, { useCallback, useEffect, useState } from 'react'
import Popup from '.'
import { TAB_BACK, TAB_FRONT, TAB_LIVELINESS } from '../../../contants/tab'
import { useGlobalContext } from '../../../contexts/global'
import Button from '../Button'

const LIST_TAB = [TAB_FRONT, TAB_BACK, TAB_LIVELINESS]

export default function ImagePopup({ isShow, listImage = [], indexInit = 0, onClose, ...rest }) {
  const { setTab } = useGlobalContext()
  const [indexSelected, setIndexSelected] = useState(indexInit)
  const [animation, setAnimation] = useState(true)
  const handleChangeIndex = useCallback((index) => {
    const size = listImage.length
    setIndexSelected((index + size) % size)
    setAnimation(false)
  }, [setIndexSelected, listImage])

  useEffect(() => {
    if (!animation) setAnimation(true)
  }, [animation])

  useEffect(() => {
    setIndexSelected(indexInit)
  }, [indexInit])
  return (
    <Popup
      isShow={isShow}
      onClose={onClose}
      classWrap="slide-image"
      {...rest}
    >
      <div className='slide-image-item relative'>
        <span className='slide-image__close' onClick={onClose}>&#x2715;</span>
        <img alt='detail' src={listImage?.[indexSelected]} className={`slide-image__img ${animation ? 'animation-fade' : ''}`} />
        <span className='slide-image__prev' onClick={() => handleChangeIndex(indexSelected - 1)}>&#10094;</span>
        <span className='slide-image__next' onClick={() => handleChangeIndex(indexSelected + 1)}>&#10095;</span>
        {/* <div className='slide-image__thumbnail'>
          {
            [...Array(listImage.length).keys()].map(index => (
              <span key={`slide-image-thumbnail-dot-${index}`} className={`slide-image__thumbnail__dot ${index === indexSelected ? 'active' : ''}`} onClick={() => handleChangeIndex(index)}></span>
            ))
          }
        </div> */}
        <div className='slide-image__button-recheck'>
          <Button type="submit" text="Chụp lại" mt={0} className="" onClick={() => setTab(LIST_TAB[indexSelected])} />
        </div>
      </div>
    </Popup>
  )
}
